import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="A" transform="translate(35.000000, 30.000000)">
        <path
          d="M 26.782 35.547 L 22.803 24.927 L 5.396 24.927 L 1.44 35.547 L 0 35.547 L 13.306 0 L 14.917 0 L 28.223 35.547 L 26.782 35.547 Z M 14.111 1.587 L 5.884 23.608 L 22.314 23.608 L 14.111 1.587 Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="3"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
